<template>
  <MapViews :filterDevicesList="filterDevicesList" :displayOnly="displayOnly" :unassigned="unassigned" :dragOnly="dragOnly"/>
</template>

<script>
  import MapViews from '@/pages/mapView/MapView.vue';

  export default {
    components: {
      MapViews
    },
    name: 'MapView',
    props: ['filterDevicesList', 'displayOnly', 'unassigned', 'dragOnly']
  };
</script>